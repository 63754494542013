import { useAuth0 } from '@auth0/auth0-react'
import { css } from '@emotion/react'
import { NextPage } from 'next'
import Head from 'next/head'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import slugify from 'slugify'

import { ErrorPageTemplate } from '@/components/global/ErrorPageTemplate'
import { useUser } from '@/components/global/hooks/useUser'
import { Loading } from '@/components/Loading'
import { Image } from '@/design-system/Image'
import { Text } from '@/design-system/Text'

const IndexPage: NextPage = () => {
  const { user } = useUser()
  const { isLoading, isAuthenticated } = useAuth0()
  const router = useRouter()
  const [ready, setReady] = useState(false)

  useEffect(() => {
    if (!ready) {
      setReady(true)
    }
  }, [])

  useEffect(() => {
    if (user) {
      if (user.dealers.length === 0) return
      const favoriteDealer = user.favoriteDealer
      if (favoriteDealer) {
        router.push(`/${slugify(favoriteDealer.name, { lower: true })}/home`)
      }

      router.push(`/${slugify(user.dealers[0].name, { lower: true })}/home`)
    }
  }, [user, router])

  if (!ready) {
    return null
  }

  if (!isAuthenticated && !isLoading) {
    router.push('/login')
    return null
  }

  if (user && user.dealers.length === 0) {
    return (
      <>
        <Head>
          <title>driverseat</title>
        </Head>
        <ErrorPageTemplate>
          <div
            css={css`
              display: flex;
              flex-direction: column;
              align-items: center;
            `}
          >
            <div
              css={css`
                position: relative;
                margin-bottom: 50px;
              `}
            >
              <Image
                src="NotFound_uf0zar"
                width={311}
                height={200}
                className="object-contain"
                alt="Not found"
              />
            </div>

            <Text
              fontSize={20}
              css={css`
                color: var(--color-blue-950-rgb);
                margin-bottom: 40px;
              `}
            >
              You're not part of any dealerships
            </Text>
          </div>
        </ErrorPageTemplate>
      </>
    )
  }

  return (
    <>
      <Head>
        <title>Driverseat App</title>
      </Head>
      <Loading fullScreen />
    </>
  )
}

export default IndexPage
